import React, {useEffect, useState, useCallback} from 'react';

import _ from 'lodash';

import firebase from 'firebase/app';
import 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';

import { useTranslation } from 'react-i18next';


import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { useCollection } from 'react-firebase-hooks/firestore';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import ClassificationReference from './ClassificationReference';

import ImageZoom from './ImageZoom';

import {REJECTED_COLOR, ACCEPTED_COLOR, REJECTED_KEY, ACCEPTED_KEY} from '../Defines';

import notificationSound from '../assets/appointed.mp3';




const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  updateSnack: {
    backgroundColor: '#FFC50B',
  },
  title:{
    flexGrow: 1,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  accept: {
    backgroundColor: ACCEPTED_COLOR,
  },
  reject: {
    backgroundColor: REJECTED_COLOR,
    color: 'white',
  },
  appbar: {
    backgroundColor: 'grey',
  },


  gridRoot: {
    display: 'flex',
    flexGrow: 1,
    padding: '4px',
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    padding: '0px',
    margin: '0px',
  },
  mainImageContainer: {
    height: 'calc(100vh - 72px)',
    display: 'flex',
    justifyContent: 'center', //flex-end
    alignItems: 'center', //flex-end
  },

  sideImagePaper : {
    height: 'calc(100vh - 72px)',
    overflow: 'hidden',
  },
  accepted: {
    background: `repeating-linear-gradient(
      45deg,
      ${ACCEPTED_COLOR},
      ${ACCEPTED_COLOR} 2px,
      #303030 2px,
      #303030 48px
    )`,
  },
  rejected: {
    background: `repeating-linear-gradient(
      45deg,
      ${REJECTED_COLOR},
      ${REJECTED_COLOR} 2px,
      #303030 2px,
      #303030 48px
    )`,
  },


}));


function handleAcception(mainDoc) {
  if (mainDoc === "-1" || !mainDoc){
    return;
  }
  mainDoc.ref.update(
    {
      classification: 'accepted',
      classificationSource: 'supervisor',
      classificationTime: firebase.firestore.FieldValue.serverTimestamp(),
      classifications: firebase.firestore.FieldValue.arrayUnion(
        {
          classification: 'accepted',
          classificationSource: 'supervisor',
          localClassificationTime: new Date(),
        }
      ),
      _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then(function() {
      console.log("correct: updated..");
    });
}

function handleRejection(mainDoc) {
  if (mainDoc === "-1" || !mainDoc){
    return;
  }
  mainDoc.ref.update(
    {
      classification: 'rejected',
      classificationSource: 'supervisor',
      classificationTime: firebase.firestore.FieldValue.serverTimestamp(),
      classifications: firebase.firestore.FieldValue.arrayUnion(
        {
          classification: 'rejected',
          classificationSource: 'supervisor',
          localClassificationTime: new Date(),
        }
      ),
      _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then(function() {
      console.log("defect: updated..");
    });
}



function Container(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [mainDoc, setMainDoc] = useState('-1');
  const [taskId, setTaskId] = useState('-1');

  const [size, setSize] = useState({width: 640, height: 480, scale: {x: 1, y: 1}});
  const [sideSize, setSideSize] = useState({width: 320, height: 240, scale: {x: 1, y: 1}});
  const [resizeCounter, setResizeCounter] = useState(0);



  useEffect(()=>{
    let observer =   firebase.firestore().collectionGroup('taskimages')
        .where('classification', '==', null)
        .where('isImageUploaded', '==', true)
        .orderBy('_createdAt', 'desc')
        .limit(1)
    .onSnapshot(querySnapshot => {
      console.log('onSnapshot', querySnapshot)
        setMainDoc(querySnapshot.docs[0]);
        if (querySnapshot.docs[0]) {
            setTaskId(querySnapshot.docs[0].data().taskId);
        }
    });
    return (()=>{observer();}); // cleanup
  },[]);






    const [rejectedValue, rejectedLoading, rejectedError] = useCollection(
      firebase.firestore().collectionGroup('taskimages')
        .where('taskId','==',taskId)
        .where('classification', '==', 'rejected')
        .where('classificationSource', '==', 'operator')
        .orderBy('_updatedAt', 'desc')
        .limit(10)
    );
    if (rejectedError) console.error(rejectedError);

    const [acceptedValue, acceptedLoading, acceptedError] = useCollection(
      firebase.firestore().collectionGroup('taskimages')
        .where('taskId','==',taskId)
        .where('classification', '==', 'accepted')
        .where('classificationSource', '==', 'operator')
        .orderBy('_updatedAt', 'desc')
        .limit(10)
    );
    if (acceptedError) console.error(acceptedError);

    const [rejectedAIValue, rejectedAILoading, rejectedAIError] = useCollection(
      firebase.firestore().collectionGroup('taskimages')
        .where('taskId','==',taskId)
        .where('classification', '==', 'rejected')
        .where('classificationSource', '==', 'supervisor')
        .orderBy('_updatedAt', 'desc')
        .limit(10)
    );
    if (rejectedAIError) console.error(rejectedAIError);

    const [acceptedAIValue, acceptedAILoading, acceptedAIError] = useCollection(
      firebase.firestore().collectionGroup('taskimages')
        .where('taskId','==',taskId)
        .where('classification', '==', 'accepted')
        .where('classificationSource', '==', 'supervisor')
        .orderBy('_updatedAt', 'desc')
        .limit(10)
    );
    if (acceptedAIError) console.error(acceptedAIError);



  // Add event listeners
  useEffect(() => {
    // If pressed key is our target key then set to true
    function downHandler({ key }) {
      if (mainDoc !== "-1" && mainDoc) {
        switch (key) {
          default:
            console.log('Ignoring key: ' + key);
            break;
          case REJECTED_KEY:
            handleRejection(mainDoc);
            break;
          case ACCEPTED_KEY:
            handleAcception(mainDoc);
            break;
        }
      }
    }

    window.addEventListener('keydown', downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [mainDoc]); // Empty array ensures that effect is only run on mount and unmount



  let buttonsDisabled = true;
  if (mainDoc !== "-1" && mainDoc) {
    buttonsDisabled = false;
  }

  let isOnline = false;


  const [aistatusValue, aistatusLoading, aistatusError] = useDocument(
    firebase.firestore().doc('publicsettings/aistatus')
  );
  if (aistatusError) console.error(aistatusError);

  if (!aistatusLoading) {
    isOnline = _.isBoolean(aistatusValue.data()[props.uid]) && aistatusValue.data()[props.uid]; // make sure we have a boolean.
  }

  function handleOnlineChange() {
    let up = {}
    up[props.uid] = !isOnline;
    aistatusValue.ref.update(up).then(() => {
      console.log('status updated')
    })
  }




  // we use the effect to get our mainResizerCallback called again..
  useEffect(() => {
    function resizer () {
      setResizeCounter(c => c + 1);
    }
    window.addEventListener("resize", resizer);
    return () => window.removeEventListener("resize", resizer);
  }, []);

  let mainDocData = {};
  let isMainDocLoaded = false;
  if (mainDoc !== "-1" && mainDoc && mainDoc.data()) {
    mainDocData = mainDoc.data();
    isMainDocLoaded = true;
  }


  let crop = {}
  if (isMainDocLoaded) {
    crop = {
      x: mainDocData.region.left,
      y: mainDocData.region.top,
      width: mainDocData.region.right-mainDocData.region.left,
      height: mainDocData.region.bottom-mainDocData.region.top,
    };
  } else {

  }

  const mainResizerCallback = useCallback((node) => {
    if (node !== null) {
      let scale = Math.min(
                            node.offsetWidth / crop.width,
                            node.offsetHeight / crop.height,
                          );
      setSize({width: crop.width*scale, height:crop.height*scale, scale: {x: scale, y:scale}})
    }
  //eslint-disable-next-line
  }, [resizeCounter, crop.width, crop.height]); // NOTE: we use the resizecounter here to recalculate whenever a resize event happens..

  const sideResizerCallback = useCallback((node) => {
    if (node !== null) {
      setSideSize({width: node.offsetWidth, height:node.offsetHeight, scale: {x: 1, y:1}})
    }
  //eslint-disable-next-line
  }, [resizeCounter]); // NOTE: we use the resizecounter here to recalculate whenever a resize event happens..


  let acceptedDocs = [];
  if (acceptedValue && acceptedValue.docs) {
    acceptedDocs = _.concat(acceptedDocs, acceptedValue.docs);
  }

  if (acceptedAIValue && acceptedAIValue.docs) {
    acceptedDocs = _.concat(acceptedDocs, acceptedAIValue.docs);
  }

  let rejectedDocs = [];
  if (rejectedValue && rejectedValue.docs) {
    rejectedDocs = _.concat(rejectedDocs, rejectedValue.docs);
  }

  if (rejectedAIValue && rejectedAIValue.docs) {
    rejectedDocs = _.concat(rejectedDocs, rejectedAIValue.docs);
  }




  return (
    <React.Fragment>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <Button variant="contained" className={clsx(classes.button, classes.accept)} onClick={_.partial(handleAcception, mainDoc)} disabled={buttonsDisabled}>{t('Accept')}</Button>
            <Button variant="contained" className={clsx(classes.button, classes.reject)} onClick={_.partial(handleRejection, mainDoc)} disabled={buttonsDisabled}>{t('Reject')}</Button>

          </Typography>
            <div>
              <FormControlLabel control={<Switch checked={isOnline} onChange={handleOnlineChange} />} label="Aktiv" />
              <Button color="inherit" onClick={() => firebase.auth().signOut()}>Logout</Button>
            </div>
        </Toolbar>
        </AppBar>


        <div className={classes.gridRoot}>
          <Grid container spacing={1}>
             <Grid item xs={6}>
              <div ref={mainResizerCallback} className={classes.mainImageContainer}>
                { mainDocData.imageUrl &&
                  <React.Fragment>
                    <ImageZoom preventDefault={true} isZoomEnabled={true} size={size} crop={crop} image={mainDocData.imageUrl} markImage={mainDocData.markImage ? mainDocData.markImage : 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='} taskImageId={mainDocData.taskImageId}/>
                    <audio src={notificationSound} autoPlay />
                  </React.Fragment>
                }
              </div>
             </Grid>
             <Grid item xs={3} >
               <Paper ref={sideResizerCallback} className={clsx(classes.sideImagePaper, classes.paper, classes.rejected)}>
                <ClassificationReference
                  isZoomEnabled={true}
                  docs={rejectedDocs}
                  stageWidth={sideSize.width}
                  stageHeight={sideSize.height}
                  stageScale={sideSize.scale}
                />
               </Paper>
             </Grid>
             <Grid item xs={3}>
               <Paper className={clsx(classes.sideImagePaper, classes.paper, classes.accepted)}>
                 <ClassificationReference
                   isZoomEnabled={true}
                   docs={acceptedDocs}
                   stageWidth={sideSize.width}
                   stageHeight={sideSize.height}
                   stageScale={sideSize.scale}
                 />
               </Paper>
             </Grid>
           </Grid>
        </div>
      </React.Fragment>
  );

}

export default Container;
