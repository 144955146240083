import React, {useRef} from 'react';
import _ from 'lodash';

import { Layer, Image as KonvaImage } from 'react-konva';

import {REJECTED_COLOR, ACCEPTED_COLOR, REFERENCEIMAGE_DISTANCE, REFERENCEIMAGE_BORDER} from '../Defines';

import useImage from './base/useImage';



function ImageDraw (props) {
  const [image] = useImage(props.image ? props.image : "");
  const [markImage] = useImage(props.markImage ? props.markImage : "");

  const imageRef = useRef(null);
  const markImageRef = useRef(null);

    const zoomLevel = 5;

    let isZooming = false;

    const getPos = () => {
      var pos = props.stageRef.current.getPointerPosition();

      pos.x = pos.x * (zoomLevel - 1);
      pos.y = pos.y * (zoomLevel - 1);
      // pos.x = pos.x / props.stageScale;
      // pos.y = pos.y / props.stageScale;

      return pos
    }

    const startZoom = () => {
      isZooming = true;

      console.log("startZoom")

      imageRef.current.scale ({
        x: props.scale.x * zoomLevel,
        y: props.scale.x * zoomLevel,
      });

      markImageRef.current.scale ({
        x: props.scale.x * zoomLevel,
        y: props.scale.x * zoomLevel,
      });

      zoomMove();

    }

    const zoomMove = () => {
      if (!isZooming) {
        return;
      }
      var pos = getPos();

      // funky calculation of the new offset...
      let newY = props.offsetY * zoomLevel - pos.y - props.scrollTopPos * (zoomLevel-1) ;

      imageRef.current.x(REFERENCEIMAGE_BORDER* (zoomLevel-1)-pos.x);
      imageRef.current.y(newY);

      markImageRef.current.x(REFERENCEIMAGE_BORDER* (zoomLevel-1)-pos.x);
      markImageRef.current.y(newY);

      props.stageRef.current.draw()
    };


    const stopZoom = () => {
      console.log("stopZoom")
      isZooming = false;
      imageRef.current.x(REFERENCEIMAGE_BORDER);
      imageRef.current.y(props.offsetY);

      imageRef.current.scale (props.scale);

      markImageRef.current.x(REFERENCEIMAGE_BORDER);
      markImageRef.current.y(props.offsetY);

      markImageRef.current.scale (props.scale);



      props.stageRef.current.draw()
    }


  return (
    <React.Fragment>
      <KonvaImage
        ref={imageRef}
        image={image}
        width={props.crop.width}
        height={props.crop.height}
        crop={props.crop}
        scale={props.scale}
        x={props.offsetX}
        y={props.offsetY}
        stroke={props.classification === 'accepted' ? ACCEPTED_COLOR : REJECTED_COLOR }
        dash={props.classificationSource === 'operator' ? [0] : [35 / props.scale.x, 25 / props.scale.x] }
        strokeWidth={REFERENCEIMAGE_BORDER/props.scale.x}
      />
      <KonvaImage
        ref={markImageRef}
        image={markImage}
        width={props.crop.width}
        height={props.crop.height}
        crop={props.crop}
        scale={props.scale}
        y={props.offsetY}


        onMousedown={startZoom}
        onTouchstart={startZoom}
        onMouseup={stopZoom}
        onTouchend={stopZoom}
        onMousemove={zoomMove}
        onTouchmove={zoomMove}

        onMouseleave={stopZoom}
        onTouchcancel={stopZoom}
        onTouchout={stopZoom}
      />
    </React.Fragment>
  );
}

function ClassificationReferenceImageZoomer (props) {
  let offsetY = REFERENCEIMAGE_BORDER;

  return (
    <React.Fragment>
      <Layer>
      {
            _.map(props.docs, (doc => {
              let docData = doc.data();


              let crop = {
                  x: docData.region.left,
                  y: docData.region.top,
                  width: docData.region.right-docData.region.left,
                  height: docData.region.bottom-docData.region.top,
                };
                let tmpScale = Math.min(
                  (props.stageHeight-REFERENCEIMAGE_BORDER*2) / crop.height,
                  (props.stageWidth-REFERENCEIMAGE_BORDER*2) / crop.width,
                )
                let scale = {
                  x: tmpScale,
                  y: tmpScale
                }

                let output = <ImageDraw
                  key={docData.imageId}
                  crop={crop}
                  scale={scale}
                  offsetX={REFERENCEIMAGE_BORDER}
                  offsetY={offsetY}
                  image={docData.imageUrl}
                  markImage={docData.markImage}
                  classification={docData.classification}
                  classificationSource={docData.classificationSource}
                  stageRef={props.stageRef}
                  scrollTopPos={props.scrollTopPos}
                  stageScale={props.stageScale}
                  totalAcceptedHeight={props.totalAcceptedHeight}
                  stageHeight={props.stageHeight}
                />

              offsetY += REFERENCEIMAGE_DISTANCE + ((REFERENCEIMAGE_BORDER*2 + crop.height) * tmpScale); // REFERENCEIMAGE_DISTANCE is not scaled with the image scale..
              return (output);

            }))
          }

            </Layer>
          </React.Fragment>

        );

}

export default ClassificationReferenceImageZoomer;
